.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh; /* Full height of the viewport */
  text-align: center;
  position: relative;
}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 128px;
}

.hero-content {
  position: absolute;
  color: #fff;
  padding: 20px;
}

h1 {
  font-size: 2em;
  margin: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5em;
  }
  
  .hero-image {
    padding: 24px;
  }
}
