/* Shop Page Styles */
.shop-page .content-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 48px;
}

.shop-page .categories {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 20px;
  margin-bottom: 20px;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.shop-page .categories .active {
  text-decoration: underline;
}

.shop-page .categories ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 10px;
  margin: 0;
}

.shop-page .categories li {
  padding: 10px;
  margin-top: 36px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
}

.shop-page .categories li:hover {
  text-decoration: underline;
}

.shop-page .categories li:active {
  text-decoration: underline;
}

.shop-page .content {
  flex-grow: 1;
  padding: 20px;
  border-radius: 8px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.product-item {
  position: relative; /* Ensure the product item is a positioning context */
  background-color: #FFFFFF;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-item:hover {
  border-radius: 32px;
}

.special-deal-banner {
  position: absolute;
  top: -1px; /* Adjust positioning to fit your design */
  right: -1px; /* Adjust positioning to fit your design */
  width: 140px; /* Adjust width as needed */
  transform: rotate(0deg);
  transform-origin: top right;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2; /* Ensure the banner is above other content */
}

.special-deal-banner-image {
  width: 100%; /* Ensure the image covers the container */
}

.special-deal-banner-text {
  position: absolute;
  color: white; /* Adjust text color as needed */
  font-weight: bold;
  top: 42%; /* Center the text vertically */
  left: 62%; /* Center the text horizontally */
  transform: translate(-50%, -50%) rotate(45deg); /* Center the text and ensure correct rotation */
  width: 100%;
  text-align: center;
}

.product-item .product-images {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.product-item .product-images img {
  max-width: 200px;
  border: none;
  margin-bottom: 20px;
}

.product-item h3 {
  padding-bottom: 12px;
}

.product-item p {
  padding-bottom: 24px;
}

.product-price {
  font-size: 1.2em;
  color: #000;
  margin-top: 10px;
  font-weight: bold;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2; /* Ensure the banner is above other content */
  transform: translate(-50%, -50%);
  background-color: rgba(255, 254, 254, 0.737);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal img {
  max-width: 100%;
  max-height: 80vh;
  padding: 0;
}

.modal .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 26px;
  cursor: pointer;
  border: none;
  background: none;
  opacity: 50%;
}

.modal .close-btn:hover {
  cursor: pointer;
  opacity: 80%;
  color: #000;
}

.modal .prev-btn,
.modal .next-btn {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Adjust transform to only affect Y axis */
  z-index: 1001;
  opacity: 50%;
}

.modal .prev-btn {
  left: 16px; /* Adjust position inside the left edge of the modal */
}

.modal .next-btn {
  right: 16px; /* Adjust position inside the right edge of the modal */
}

.modal .prev-btn img,
.modal .next-btn img {
  width: 50px;
  height: 50px;
}

.modal .prev-btn:hover img,
.modal .next-btn:hover img {
  opacity: 80%;
}

/* Modal overlay */
.modal::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 254, 254, 0.737);
  z-index: -1;
  border-radius: 16px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: 1fr;
  }

  .modal {
    max-width: 90%;
    max-height: 90%;
    padding: 10px;
  }

  .modal img {
    max-width: 100%;
    max-height: 70vh;
  }

  .modal .close-btn {
    font-size: 20px;
  }

  .modal .prev-btn,
  .modal .next-btn {
    width: 40px;
  height: 40px;
  }
}

@media (max-width: 480px) {
  .modal {
    width: 100%;
  }

  .modal img {
    max-width: 100%;
    max-height: 60vh;
  }

  .modal .close-btn {
    font-size: 18px;
    
  }

.modal .prev-btn {
    left: 4px; /* Adjust position inside the left edge of the modal */
  }
  
  .modal .next-btn {
    right: 4px; /* Adjust position inside the right edge of the modal */
  }
  
  .modal .prev-btn, .modal .next-btn {
    width: 32px;
    height: 32px;
  }
}