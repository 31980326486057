/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General Styles */
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  background: url('./images/dot-grid-tile.png') repeat;
  background-size: 400px 400px;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

body {
  font-weight: 400; /* Regular weight for body text */
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; /* Bold weight for headers */
}

html, body {
  overflow-x: hidden; /* Prevent horizontal scroll */
  max-width: 100%; /* Ensure no overflow */
}

.header, .footer, .content-container {
  width: 100%;
  max-width: 100vw; /* Ensure no overflow */
  overflow: hidden; /* Prevent overflow */
}

/* Header Styles */

.header .logo img {
  height: 50px;
  /* Adjust height as needed */
}

.tagline-item {
  margin-left: auto; /* Aligns the tagline to the right */
  /* For left alignment, use margin-right: auto; */
  display: flex;
  align-items: center;
  padding: 0 20px; /* Adjust as needed for spacing */
  white-space: nowrap; /* Prevent text from breaking */
}

.header {
  display: flex;
  padding: 24px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.header .logo {
  font-size: 1.5em;
}

.header nav {
  display: flex;
  align-items: center;
  flex: 1; /* Allow nav to take available space */
}

.header nav .nav-items {
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  justify-content: flex-end; /* Ensure right alignment */
  flex-direction: row; /* Ensure items are always in a row */
  flex-wrap: nowrap; /* Prevent items from wrapping */
  white-space: nowrap; /* Prevent text from breaking */
}

.nav-open .nav-items {
  display: flex; /* Ensure the menu items are shown */
  flex-direction: row; /* Ensure items are always in a row */
}

.show-menu {
  display: flex !important; /* Ensure the menu items are shown */
  flex-direction: row; /* Ensure items are always in a row */
}

.header nav .nav-item {
  transition: background-color 0.3s, color 0.3s;
}

header nav .nav-item a {
  text-decoration: none; /* Ensure no text decoration on links */
  color: rgba(0, 0, 0, 0.853);
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.header nav .nav-item a:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header nav .nav-item:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styles for Homepage Books */
.homepage-books {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 48px;
}

.book-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book-container {
  width: 400px;
  height: 500px;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  padding: 10px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  position: relative;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.book-container.hovered {
  border-radius: 32px;
}

.book-container.clicked {
  border-radius: 32px;
}

.book-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure children are positioned relative to this container */
}

.book-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  padding: 20px;
  position: relative; /* Ensure children are positioned relative to this container */
  }

.book-image {
  width: 100%;
  height: auto;
}

.book-icon-container {
  position: absolute;
  bottom: 10px; /* Adjust the distance from the bottom as needed */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.book-icon {
  cursor: pointer;
  width: 24px; /* Adjust width as needed */
  height: 24px; /* Adjust height as needed */
  opacity: 40%;
}

.book-icon:hover {
  opacity: 60%;
}

.book-info {
  text-align: center;
  margin-top: 10px;
}

.book-info h3 {
  margin: 10px 0 5px 0;
}

.book-info p {
  margin: 0;
  color: #777;
}

.book-banner {
  position: absolute;
  top: -1px; /* Adjust positioning to fit your design */
  right: -1px; /* Adjust positioning to fit your design */
  width: 140px; /* Adjust width as needed */
  transform: rotate(0deg);
  transform-origin: top right;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1; /* Ensure the banner is above other content */
}

.banner-image {
  width: 100%; /* Ensure the image covers the container */
  
}

.banner-text {
  position: absolute;
  color: white; /* Adjust text color as needed */
  font-weight: bold;
  transform: rotate(45deg);
  top: 47px; /* Adjust positioning to fit your design */
  right: -13px; /* Adjust positioning to fit your design */
  width: 100%;
  text-align: center;
}



/* Footer Styles */
.footer {
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 24px;
}

.footer .logo img {
  align: center;
  height: 75px; 
  margin: 24px;
}

.hamburger {
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header {
    padding: 24px 0 0 0;
  }
  
  

  .header-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .header .logo {
    padding: 0 0 0 12px;
    margin: 0;
  }

  .tagline-item {
    padding: 12px;
    font-size: 14px;
  }

  h3 {
    font-size: 26px;
  }

  .header nav .nav-items {
    flex-direction: column;
    gap: 20px;
    display: none; /* Hide navigation items by default */
    position: absolute;
    right: 16px;
    top: 86px;
    border-radius: 18px;
    align-items: flex-end;
    z-index: 1000; /* Ensure the dropdown menu is on top */
  }

  .nav-open .nav-items {
    display: flex; /* Ensure the menu items are shown */
    flex-direction: column; /* Stack items vertically */
  }

  .nav-open nav {
    padding: 24px; /* Padding for the nav container */
  }

  .nav-open .nav-item {
    padding: 6px 0; /* Padding for each item */
  }

  .show-menu {
    display: flex !important; /* Ensure the menu items are shown */
    flex-direction: column; /* Stack items vertically */
  }

  .hamburger {
    display: block;
    padding: 14px;
    font-size: 24px;
    align-self: flex-end; /* Ensure the hamburger menu is aligned to the right */
    z-index: 1100; /* Ensure the hamburger button is above the dropdown menu */
  }

  .header nav .nav-item a {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .header nav .nav-item a:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .header nav .nav-item a:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .header .logo img {
    margin-left: 0;
    /* Adjust height as needed */
  }
  
  .book-container {
  width: 340px;
  height: 425px;
}
.book-description {
font-size: 28px;
}

}


